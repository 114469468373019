import React from "react";

import "./BotAvatar.css";

import AvatarImg from "../../img/avatar.png";

const BotAvatar = () => {
  return (
    <div className="react-chatbot-kit-chat-bot-avatar">
      <div className="react-chatbot-kit-chat-bot-avatar-container">
        <img alt="Bot Avatar" src={AvatarImg} />
      </div>
    </div>
  );
};

export default BotAvatar;
