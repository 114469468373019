import React/*, { useState }*/ from "react";

import "./EmotionalBarometer.css";

const EmotionalBarometer = (props) => {
  console.log(props);
  // let [questionIndex, setQuestionIndex] = useState(0);

  // const incrementIndex = () => setQuestionIndex((prev) => (prev += 1));

  // const currentQuestion = props.questions[questionIndex];

  // if (!currentQuestion) {
  //   return <p>Quiz over.</p>;
  // }

  // return (
  //   <FlashCard
  //     question={currentQuestion.question}
  //     answer={currentQuestion.answer}
  //     incrementIndex={incrementIndex}
  //   />
  // );

  return (
    <div className="tool-wrap">
      <div className="tool-container"></div>
    </div>
  );
};

export default EmotionalBarometer;
