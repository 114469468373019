import { createChatBotMessage } from 'react-chatbot-kit';

import Options from "./components/Options/Options";
import EmotionalBarometer from "./components/EmotionalBarometer/EmotionalBarometer";
import BotAvatar from "./components/BotAvatar/BotAvatar";

const botName = 'BrianBot';

const config = {
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: '#376B7E',
    },
    chatButton: {
      backgroundColor: '#ff6694',
    },
  },
  initialMessages: [
    createChatBotMessage(`Hi! I'm ${botName}`),
    createChatBotMessage(`What would you like to do today?`, {
      widget: "options",
    }),
  ],
  customComponents: {
    botAvatar: (props) => <BotAvatar {...props} />
  },
  widgets: [
    {
      widgetName: "options",
      widgetFunc: (props) => <Options {...props} />,
    },
    {
      widgetName: "emotionalBarometer",
      widgetFunc: (props) => <EmotionalBarometer {...props} />,
      props: {
        testProperties: [
          {
            question: "What is closure?",
            answer:
              "Closure is a way for a function to retain access to it's enclosing function scope after the execution of that function is finished.",
            id: 1,
          },
          {
            question: "Explain prototypal inheritance",
            answer:
              "Prototypal inheritance is a link between an object and an object store that holds shared properties. If a property is not found on the host object, javascript will check the prototype object.",
            id: 2,
          },
        ],
      },
    },
  ],
};

export default config;
