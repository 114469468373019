// import logo from './img/logo.svg';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css'
import './variables.css'
import './App.css';

import ActionProvider from './bot/ActionProvider';
import MessageParser from './bot/MessageParser';
import config from './bot/config';

function App() {
  return (
    <div className="App">
      <Chatbot config={config} actionProvider={ActionProvider} messageParser={MessageParser} />
    </div>
  );
}

export default App;
