import React from 'react';

// const { Configuration, OpenAIApi } = require("openai");
// const { SecretsManagerClient, GetSecretValueCommand } = require("@aws-sdk/client-secrets-manager");

// const secret_name = "Open_AI_API_Key";

// const client = new SecretsManagerClient({
//   region: "eu-west-2",
//   credentials: {
//     accessKeyId: process.env.REACT_APP_IAM_SECRETS_ACCESS_KEY_ID,
//     secretAccessKey: process.env.REACT_APP_IAM_SECRETS_ACCESS_KEY,
//   }
// });

// let secretResponse;
// var secrets = {};

// try {
//   secretResponse = await client.send(
//     new GetSecretValueCommand({
//       SecretId: secret_name,
//       VersionStage: "AWSCURRENT", // VersionStage defaults to AWSCURRENT if unspecified
//     })
//   );

//   secrets = JSON.parse(secretResponse.SecretString);
// } catch (error) {
//   // For a list of exceptions thrown, see
//   // https://docs.aws.amazon.com/secretsmanager/latest/apireference/API_GetSecretValue.html
//   throw error;
// }

// const configuration = new Configuration({
//   apiKey: secrets.OPENAI_API_KEY,
// });
// const openai = new OpenAIApi(configuration);

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const handleHello = () => {
    // TODO: Store an array of responses and randomly return one
    const responses = [
      'Hello! How can I assist you today?',
      'Hi there! How may I help you?',
      'Hey! How can I be of service?',
      'Hello! I\'m here to help. What can I do for you?',
      'Greetings! How may I assist you on this fine day?',
      'Greetings and salutations! How may I be of help?'
    ];

    const randomIndex = Math.floor(Math.random() * responses.length);

    const botMessage = createChatBotMessage(responses[randomIndex]);

    addMessageToState(botMessage);
  };

  const handleOptionEBSubmit = () => {
    const botMessage = createChatBotMessage(
      "Submit your EB entry. Some text here to give a reminder of using it, or link to more information...",
      {
        widget: "emotionalBarometer",
      }
    );

    addMessageToState(botMessage);
  };

  const handleOptionAskQuestion = () => {
    const botMessage = createChatBotMessage("How can I help?");

    addMessageToState(botMessage);
  };

  const handleAIPrompt = async (message) => {
    var responseMessage = '';
    const url = process.env.REACT_APP_CHAT_BACKEND_URL;

    const data = new FormData();
    data.append('user_message', message);

    try {
      const response = await fetch(url, {
        method: 'POST',
        // headers: {
        //   // Although not using AJAX, we will send the header to help identify the request in the backend
        //   'X-Requested-With': 'XMLHttpRequest',
        // },
        body: data,
      });

      if (response.ok) {
        // Request was successful
        const responseData = await response.json();

        responseMessage = responseData.responseText;
      } else {
        // Request failed
        responseMessage = 'Error: ' +  response.status + ' ' + response.statusText;
      }
    } catch (error) {
      responseMessage = 'Error: ' + error.message;
    }

    const botMessage = createChatBotMessage(responseMessage);

    addMessageToState(botMessage);
  };

  const addMessageToState = (message) => {
    setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
            handleAIPrompt,
            handleOptionEBSubmit,
            handleOptionAskQuestion
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
